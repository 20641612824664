import React from 'react';
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';

const Colors = {
  fullBlack: '#000000',
  black: '#222222',
  white: '#FFFFFF',
  grayLight: '#CCCCCC',
  grayLighter: '#EEEEEE',
  grayDark: '#555555',
  red: '#f55539',
};

const Sub = styled.p`
  font-size: 16px;
  margin-bottom: 0;
`;

const GlobalStyles = () => (
  <Global
    styles={css`
      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }

      body {
        --color-background: rgb(51, 51, 51);
        --color-on-background: #fff;

        --color-tooltip: rgba(0, 5, 11, 0.9);
        --color-on-tooltip: #fff;

        --color-handle-fill: #2c343a;
        --color-handle-stroke: #67c2e4;
        --color-handle-drag-fill: transparent;
        --color-handle-dots: #67c2e4;
        --color-handle-line: #67c2e4;
      }

      img {
        max-width: 100%;
        height: auto;
      }

      a:not(.escape) {
        color: ${Colors.red};
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        &.white-link {
          color: ${Colors.white};
        }

        &[target='_blank'] {
          &::after {
            content: url("data:image/svg+xml,%3Csvg stroke='%23f55539' fill='none' stroke-width='0' viewBox='0 0 28 28' height='14px' width='14px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14'%3E%3C/path%3E%3C/svg%3E");
            margin-left: 1px;
            display: inline-block;
          }
        }
      }

      html {
        font-size: 16px;
      }

      body {
        font-family: 'Titillium Web', sans-serif;
      }

      [language]:not([language='']) {
        font-size: 14px;
        line-height: 20px;
        margin-top: 15px;
      }

      h1,
      .h1 {
        font-size: 56px;
        line-height: 60px;
        @media (min-width: 768px) {
          font-size: 76px;
          line-height: 90px;
        }
        margin-top: 30px;
        margin-bottom: 60px;
      }

      h2,
      .h2 {
        font-size: 47px;
        line-height: 60px;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      h3,
      .h3 {
        font-size: 29px;
        line-height: 30px;
        margin-top: 30px;
        margin-bottom: 0px;
      }

      h4,
      .h4 {
        font-size: 18px;
        line-height: 30px;
        margin-top: 30px;
        margin-bottom: 0px;
      }

      h5,
      .h5 {
        font-size: 18px;
        line-height: 30px;
        margin-top: 30px;
        margin-bottom: 0px;
      }

      p {
        margin-top: 15px;
        margin-bottom: 15px;
        & + p {
          margin-top: 0;
        }

        & + ul,
        & + ol {
        }
      }

      ul,
      ol {
        padding-left: 1rem;
      }

      ul,
      ol,
      pre,
      table,
      blockquote {
        margin-top: 0px;
        margin-bottom: 15px;
      }
      ul ul,
      ol ol,
      ul ol,
      ol ul {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 1rem;
      }

      /* Let's make sure all's aligned */
      hr,
      .hr {
        border: 1px solid;
        margin: -1px 0;
      }
      a,
      b,
      i,
      strong,
      em,
      small,
      code {
        line-height: 0;
      }
      code {
        line-height: normal;
      }
      sub,
      sup {
        line-height: 0;
        position: relative;
        vertical-align: baseline;
      }
      sup {
        top: -0.5em;
      }
      sub {
        bottom: -0.25em;
      }

      label {
        display: block;
        margin-top: 30px;
      }

      form ${Sub} {
        margin-top: 0;
        color: ${Colors.grayDark};
      }

      code {
        color: ${Colors.red};
        background: ${Colors.grayLighter};
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        font-family: 'IBM Plex Mono';
      }

      input,
      textarea {
        font-family: inherit;
        border: 2px solid ${Colors.fullBlack};
        width: 100%;
        padding: 15px;
      }

      input {
        display: block;
      }

      textarea {
        display: block;
        height: 240px;
      }
      [id^='fnref'] ~ a {
        display: inline-block;
      }

      blockquote {
        font-size: 1.25rem;
        line-height: 1.2;
        font-weight: bold;
        border-left: 3px solid;
        padding-left: 12px;
        @media (min-width: 768px) {
          font-size: 1.5rem;
          line-height: 1.02;
        }
        p {
          margin: 0;
        }
      }
    `}
  />
);

const Container = styled.div`
  max-width: 1200px;
  padding: ${(props) => (props.noPadding ? `0` : `0 30px;`)}
  margin: 0 auto;  
`;

const MinPageHeight = styled.article`
  min-height: 100vh;
`;

const Card = styled.article`
  border-radius: 8px;
  margin-top: 45px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 4px 16px;
  background: ${Colors.white};
`;

const CardHeader = styled.section`
  padding: 30px 30px 0 30px;
`;

const CardBody = styled.section`
  padding: 30px;
  p {
    margin-bottom: 0;
  }
`;

const CardBodySplit = styled.section`
  display: grid;
  grid-template-columns: 100%;
  @media (min-width: 768px) {
    grid-template-columns: 50% 50%;
  }

  > div {
    padding: 30px;
  }
  img {
    user-select: none;
  }
`;

const CardCopy = styled.p`
  margin-top: 30px;
`;

const CardFooter = styled.section`
  padding: 30px;
`;

const CardTitle = styled.div`
  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }
`;

const SpacedSpan = styled.span`
  display: inline-block;
  width: 75px;
  text-align: center;
`;

const Button = styled.button`
  cursor: pointer;
  font-family: inherit;
  border: 2px solid ${Colors.fullBlack};
  &:hover {
    background: ${Colors.fullBlack};
    color: ${Colors.white};
  }
  text-transform: uppercase;
  font-size: 16px;
  margin-right: 15px;
  padding: 15px 45px;
  font-weight: bold;
  color: ${Colors.fullBlack};
  margin-top: 45px;
  letter-spacing: 0.12em;
`;

const WhiteButton = styled.button`
  cursor: pointer;
  font-family: inherit;
  border: 2px solid ${Colors.white};
  color: ${Colors.fullBlack};
  &:hover {
    border: 2px solid ${Colors.grayDark};
    background: ${Colors.grayDark};
    color: ${Colors.white};
  }
  text-transform: uppercase;
  font-size: 16px;
  margin-right: 15px;
  padding: 15px 45px;
  font-weight: bold;
  margin-top: 45px;
  letter-spacing: 0.12em;
`;

const ToggleIcon = styled.div`
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 8px;
  cursor: pointer;
  transition: 0.25s;
  border-radius: 50%;
  background: ${(props) => (props.inverse ? Colors.white : Colors.fullBlack)};

  svg {
    transition: 0.5s;
  }
`;

const RelativeBox = styled.div`
  position: relative;
`;

const PaddedBox = styled.div`
  padding: 15px;

  ${CardCopy} {
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const HeroWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeroSection = styled.section`
  position: relative;
  min-height: 100vh;
`;

const TextCenter = styled.div`
  text-align: center;
`;

const TextMuted = styled.p`
  color: ${Colors.grayLight};
`;

const TextMutedDark = styled.p`
  color: ${Colors.grayDark};
`;

const Flag = styled.div`
  display: grid;
  grid-template-columns: 75px 1fr;
  grid-auto-rows: auto;
  grid-gap: 15px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.25s;

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  &:hover {
    background: ${(props) => (props.hover ? props.hover : 'transparent')};
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.05);
  }

  &.selected {
    background: ${(props) => (props.hover ? props.hover : 'transparent')};
    box-shadow: ${(props) =>
      props.shadow
        ? `0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.05)`
        : null};
  }

  &:first-of-type {
    margin-top: 0;
  }

  h4 {
    margin-top: 0;
  }

  p {
    margin: 15px 0;
  }
`;

const SubHeading = styled.h5`
  font-weight: normal;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 11px;
`;

const FullPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const BackgroundDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  img {
    object-fit: cover !important;
  }
`;

const BlogPostGrid = styled.article`
  display: grid;
  grid-template-columns: 1fr min(70ch, 100%) 1fr;

  @media (min-width: 768px) {
    padding: 0;
  }

  & > * {
    grid-column: 2;
  }

  a:not(.escape) {
    line-height: 1.5;
    color: ${Colors.red};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 0;

    a {
      background: none;
    }
  }

  ul::not(.escape) {
    padding-left: 1rem;
    li::marker {
      font-size: 150%;
      color: ${Colors.red};
    }
  }

  li {
    p {
      margin: 0;
    }
    &:first-of-type p {
      margin-top: 30px;
    }
    &:last-of-type p {
      margin-bottom: 30px;
    }
  }

  ol {
    margin-top: 15px;
  }

  ol li {
    counter-increment: blogcounter;
    display: flex;
    margin-bottom: 10px;
  }

  ol li:before {
    content: counter(blogcounter);
    width: 25px;
    height: 25px;
    flex: 0 0 25px;
    background: black;
    color: white;
    border-radius: 50%;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-right: 0.5rem;
  }

  ol {
    list-style: none;
    counter-reset: blogcounter;
  }

  img {
    border-radius: 8px;
    border: 1px solid #ccc;
    margin: 16px auto 16px auto;
  }
`;

const FullBleed = styled.section`
  width: 100%;
  grid-column: 1 / 4;
`;

const PartialBleed = styled.section`
  width: 100%;
  grid-column: 1 / 4;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const BlogArchiveList = styled.ul`
  padding-left: 0;
  margin-top: 1rem;
`;

export default GlobalStyles;
export {
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardBodySplit,
  CardCopy,
  Button,
  WhiteButton,
  ToggleIcon,
  PaddedBox,
  HeroWrapper,
  HeroSection,
  TextCenter,
  Sub,
  Colors,
  Flag,
  TextMuted,
  TextMutedDark,
  SubHeading,
  FullPanel,
  BackgroundDiv,
  SpacedSpan,
  RelativeBox,
  MinPageHeight,
  BlogPostGrid,
  PartialBleed,
  FullBleed,
  BlogArchiveList,
};
